<template>
  <div class="px-2 mx-auto">
    <h2 class="align-center font-weight-bold col-12 col-md-7 mx-auto" style="font-size: 1.5rem">
      Badania
      dotyczące diagnozy
      potencjału rynku
      loterii promocyjnych,
      <br>jako narzędzia wspierającego sprzedaż
    </h2>
    <div class="col-9 col-md-5 mx-auto pt-4">
      <img  class="image" src="../assets/table.jpg"/>
    </div>

    <p class="col-11 col-md-9 mx-auto font-weight-bold pt-4">Zapraszamy do wzięcia udziału w badaniu efektywności wykorzystania loterii promocyjnych jako narzędzia sprzedaży. Prosimy o podzielenie się swoimi opiniami i doświadczeniami, na bazie których powstanie pierwszy w Polsce raport dotyczący potencjału rynku loterii promocyjnych. Udział w badaniu jest anonimowy i trwa od 5 do 10 min. W zamian za udzielenie odpowiedzi, podzielimy się wynikami badania, które zostaną przesłane na podany na końcu ankiety adres e-mail.
      </p>

    <p class="col-11 col-md-9 mx-auto font-weight-bold">Partnerzy akcji:</p>

    <div class="row col-11 col-md-9 d-flex justify-content-center mb-3 mt-2 mx-auto">
       <div class="col-6 col-md-3 d-flex align-items-center"><div><img class="w-75 w-md-50" src="../assets/1.png"/></div></div>
       <div class="col-6 col-md-3 d-flex align-items-center"><div><img class="w-75 w-md-50" src="../assets/2.png"/></div></div>
       <div class="col-6 col-md-3 d-flex align-items-center"><div><img class="w-75 w-md-50" src="../assets/3.png"/></div></div>
       <div class="col-6 col-md-3 d-flex align-items-center"><div><img class="w-75 w-md-50" src="../assets/4.png"/></div></div>
    </div>

    <p
        style="margin-left: auto; margin-right: auto; width: 70%; text-align: center; padding-bottom: 30px; font-size: 13px; font-style: italic !important; font-family: Arial, Helvetica, sans-serif;">
      Ilekroć w pytaniu
      pojawia się słowo
      “loteria”, o ile nie zostało one doprecyzowane, chodzi o cały czas trwania promocji: od dnia startu
      promocji, przez czas przyjmowania zgłoszeń, losowanie, wydanie nagród, czas na reklamacje uczestników
      oraz udzielenie na nie odpowiedzi.</p>
    <div style="margin: 0 auto; display: flex; justify-content: center">
      <router-link :to="'/'+slug">
         <button  class="rounded next-button mb-5 font-weight-bold">PRZEJDŹ DO ANKIETY</button>
      </router-link>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: 'FirstPage',
  props: {
    formnext: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      errored: false,
      survey: null,
      slug: '',
    }
  },
  async mounted () {
    await axios
//api-ankieta.grzegrzolka.com
//api-ankieta.dev-is.pl
        .get('https://api-ankieta.grzegrzolka.com/questionnaire/list')
        .then(response => {
         
          this.survey = response.data.archive[0]
          this.slug = response.data.archive[0].slug
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.loading = false)
  }

}
</script>

<style scoped>




div[data-toggle="buttons"] label {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 2em;
  text-align: left;
  white-space: nowrap;
  vertical-align: top;
  cursor: pointer;
  background-color: none;
  border: 0px solid #c8c8c8;
  border-radius: 3px;
  color: #c8c8c8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}


.image {
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 6px 6px 14px 0 rgba(0, 0, 0, 0.2), -8px -8px 18px 0 rgba(255, 255, 255, 0.55);
}

.next-button {
  display: inline-block;
  border: none;
  padding: 1.4rem 2.4rem;
  margin: 0;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: white !important;
}

button:hover,
button:focus {
  background: #0053ba;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

</style>

