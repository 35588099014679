
<template>
  <div class="col-12 p-0" :class="loading ? 'loading' : ''">
    <div v-if="endSurvey"  class="pt-5 mt-5 font-weight-bold px-2" style="font-size: 25px; line-height: 25px">Dziękujemy za czas poświęcony na wypełnienie ankiety.
      <div class="row col-11 col-md-9 d-flex justify-content-center mb-3 mt-2 mx-auto mt-5">
        <div class="col-6 col-md-3 d-flex align-items-center"><div><img class="w-75 w-md-50" src="../assets/1.png"/></div></div>
        <div class="col-6 col-md-3 d-flex align-items-center"><div><img class="w-75 w-md-50" src="../assets/2.png"/></div></div>
        <div class="col-6 col-md-3 d-flex align-items-center"><div><img class="w-75 w-md-50" src="../assets/3.png"/></div></div>
        <div class="col-6 col-md-3 d-flex align-items-center"><div><img class="w-75 w-md-50" src="../assets/4.png"/></div></div>
      </div>
    </div>
    <div v-if="errored">
      <p v-if="errorList.indexOf('Dla tego adresu e-mail została już wysłana ankieta.') < 0" class="pt-5 mt-5 text-danger">Wystąpił błąd, spróbuj póżniej.</p>
      <div v-if="errorList !== 'Error'">
        <li  v-for="error in errorList" :key="error">{{error}}</li>
      </div>
    </div>
    <div v-if="!endSurvey && !errored &&  !modal" class="row col-12  col-md-8 mx-auto form-container my-5 py-4 px-3 px-md-5 md-rounded">
      <div v-for="(question, index) in survey" :key="question.id" class="py-3 w-100 text-left">


        <h5 v-if="index > 1" class="mt-2">Pytanie: {{index + 1}} z {{postData.answers[0].answers == 'nie' ? 6 : 28}}</h5>

        <!--SLIDER-->
        <div  v-if="question.type=='slider' && !modal" :class="question.type+'type'" class="w-100 d-flex flex-column">
          <label :for="question.id" class="form-label mr-5 font-weight-bold col-11 px-0">{{index + 1 + '. '}} {{question.content}}</label>

          <div v-for="(answer, index) in question.answers" :key="index">
            <p class="pt-3 font-weight-bold mb-0">{{answer.content}}</p>
            <div class="col-10 px-0">
              <span>{{question.scaleFrom}} </span>
              <input   :name="question.id" :id="question.id" v-on:change="sliderAnswers($event, answer)" style="width: 300px; position: relative; top: 3px" type="range" class="form-range col col-10 px-0" :min="question.scaleFrom" :max="question.scaleTo" :step="question.scaleStep"  value="0" >
              <span> {{question.scaleTo}}</span>
            </div>
          </div>
        </div>

        <!--OPEN-->
        <div v-if="question.type=='open' && !modal" :class="question.type+'type'" class="d-flex w-100 flex-column">
          <p class="px-0 font-weight-bold col-10">{{index + 1 + '. '}} {{question.content}}</p>
          <textarea maxlength="3000"  class="col-10" :name="question.id" v-on:keyup="openAnswer($event)" :id="question.id" value=""  placeholder="Wpisz odpowiedź"></textarea>
          <p v-if="question.id == 86">{{questionCounter}}/3000</p>
        </div>

        <!--RADIO-->
        <div v-if="question.type=='once-text' && !modal" :class="question.type+'type'" class="w-100">
          <h5 v-if="question.text" class="px-0 pr-4 font-weight-bold py-3">Proszę udzielić odpowiedzi na kolejne pytania w odniesieniu do <span style="text-decoration: underline;">ostatnio realizowanej loterii promocyjnej</span>.</h5>
          <p class="px-0 pr-4 font-weight-bold">{{index + 1 + '. '}} {{question.content}}</p>
          <div class="">
            <div v-for="(answer, index) in question.answers" :key="index">
              <div class="d-flex flex-row">
                <div>
                  <input v-if="!answer.text" class="mr-2" v-on:change="oneAnswer($event, answer)" type="radio" :id="answer.id" :value="answer.id" :name="question.id">
                </div>
                <div>
                  <label v-if="!answer.text" class=" my-0" :for="answer.id">{{answer.content}}</label>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div>
                  <input v-if="answer.text" class="mr-2" v-on:change="radioOther($event, answer)" type="radio" :id="question.id" :value="currentOther[question.id]" :name="question.id">
                </div>
                <div>
                  <input v-if="answer.text" :class="question.id" :id="question.id" :name="question.id" :disabled="false" v-model="currentOther[question.id]" v-on:keyup="oneAnswer($event, answer)"  typt="text" placeholder="Inne">
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--MULTIPLE-->
        <div v-if="question.type=='multiple-text' && !modal"  class="w-100">
          <p class="font-weight-bold">{{index + 1 + '. '}}{{question.content}}</p>
          <div v-for="(answer, index) in question.answers" :key="index">
            <input v-if="!answer.text" class="mr-2" v-on:click="checkboxAnswer($event, answer)" type="checkbox" :id="answer.id"  :value="answer.content" :name="question.id">
            <label v-if="!answer.text"  :for="answer.id">{{ answer.content }}</label>
            <input v-if="answer.content == 'inne'" class="mr-2"  type="checkbox" :id="answer.id" :value="checkBoxOther[answer.id]" :name="question.id" v-on:click="checkboxAnswer($event, answer)">
            <input v-if="answer.text" :class="question.id" :id="answer.id" :name="question.id" :disabled="false" v-model="checkBoxOther[answer.id]" v-on:change="checkboxAnswerText($event, answer)" typt="text" placeholder="Inne">
          </div>
        </div>


        <!--MATRIX-->
        <div v-if="question.type=='matrix' && !modal" class="w-100">
          <p class="font-weight-bold">{{index + 1 + '. '}}{{question.content}}</p>
          <table class="table-responsive table table-bordered">
            <thead>
            <tr>
              <th scope="col" class="px-0 col-1"></th>
              <th scope="col" class="px-2 col-1 text-center" v-for="(header, index) in question.headers.split('|')" :key="index">{{ header }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(answer, index) in question.answers" :key="index">
              <th class="text-center" scope="row">{{answer.content}}</th>
              <td style="height: 1px" v-for="(radio, index) in question.headers.split('|')" :key="index">
                <div class="w-100 d-flex justify-content-center align-center p-3" style="position: relative; height: 100%">
                  <input style="align-self: center" v-on:click="matrixAnswers(question.id, $event, radio)" type="radio" :name="answer.id" :id="radio+answer.id" :value="radio">
                  <label :for="radio+answer.id" style=" position:absolute; left: 0; top: 0; width: 100%; height: 100%"></label>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--      errors-->
      <div v-if="!modal" class="d-flex flex-column justify-content-center mx-auto">
        <div v-if="errors.length" class="d-flex flex-column justify-content-center mx-auto rounded mb-2">
          <p class="font-weight-bold px-3">BRAK ODPOWIEDZI NA PYTANIA:</p>
          <li class="list-group-item-danger text-center mx-auto mb-1 px-3" v-for="(error,index) in errors" :key="index">{{error}}</li>
        </div>
        <div v-if="multiError.length > 0" class="d-flex flex-column justify-content-center mx-auto rounded pb-0 mb-0 pt-2">
          <p class="font-weight-bold px-3 mb-0 pb-0">MAKSYMALNA ILOŚĆ ODPOWIEDZI W PYTANIACH:</p>
          <li class="list-group-item-danger text-center mx-auto mb-1 px-3" v-for="(multiError,index) in multiError" :key="index">{{multiError}}</li>
          <p class="font-weight-bold px-3 mb-0 pb-0 mt-0 pt-0">TO 3</p>
        </div>
        <button v-on:click="submit" class="next-button mb-5 mx-auto mt-3 rounded font-weight-bold">PRZEJDŹ DALEJ</button>
      </div>

      <!--      MODAL-->
    </div>

     <div v-if="modal && !errored"  class="email-modal d-flex flex-column mb-0 pb-0 pt-5 col-12 mx-auto px-3 px-md-5 col-12 col-lg-6">

       <!--      <button v-on:click="modal = false" class="btn-closes">X</button>-->
      <p v-if="!agency" class="text-center"> Jeśli chce Pan/Pani otrzymać raport z badań,<br> prosimy zaznaczyć wszystkie zgody poniżej.</p>

      <input class="col-10 col-md-3 mx-auto" type="email" name="usremail" v-model="emailValue">
      <p v-if="emailValMsg !== ''" class="text-danger pt-3 pb-0 mb-0">{{emailValMsg}}</p>
      <div class="mt-4">
        <div v-for="(approval, index) in approvals" :key="index" class="pt-3 d-flex justify-content-start" style="position: relative">
          <p v-if="approval.req && !approvals[index].value" class="text-danger pl-3" style="font-size: 11px; position: absolute; top: -2px; left: 0px ">*Pole obowiązkowe</p>
          <div class="d-flex align-center">
            <div class="col-1">
              <input type="checkbox" v-on:click="approvals[index].value == false ? approvals[index].value = true : approvals[index].value = false " :id="index" :value="approval" class="mr-2 text-left mt-1">
            </div>
            <label :for="index" class="text-left approve col-11">{{approval.content}}</label>
          </div>
        </div>
      </div>
      <p class="text-left"><button v-on:click="more? more = false :more = true" style="background-color: transparent; border: none; text-decoration: underline; font-size: 10px">
        {{ !more ? 'Więcej' : 'Mniej' }}</button></p>
        <div v-if="more" class="moreRules text-left">
          <p class="font-weight-bold">Na podstawie Rozporządzenia Parlamentu Europejskiego i Rady (UE) nr 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE („Ogólne rozporządzenie o ochronie danych”, Dz.Urz.UEL119 z 04.05.2016 str.1), informujemy, że:  </p>
          <p class="py-0 my-0">1. Administratorem danych osobowych osób, które wypełniły Ankietę w celu urządzenia, przeprowadzenia i podsumowania Ankiety jest Grzegrzółka&Ko Sp. z o.o. z siedzibą w Gdyni (81-335) przy ul. Janka Wiśniewskiego 13, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem 0000254913 o kapitale zakładowym w wysokości 50.000 zł, opłaconym w całości, posiadająca NIP: 7010016236, posiadająca numer statystyczny REGON 140487829., Administrator wyznaczył Inspektora Ochrony Danych, z którym można skontaktować się poprzez wysłanie wiadomości na adres e-mail dane@grzegrzolka.com lub pisemnie na adres siedziby administratora.  </p>
          <p class="py-0 my-0">2. Dane osobowe osób, które wypełniły Ankietę są przetwarzane zgodnie z powszechnie obowiązującymi przepisami prawa, wyłącznie w celu związanym z organizacją przedmiotowej Ankiety – odpowiedzi są anonimowo wykorzystywane do opracowania wyników badania zaś adresy e-mail osób, które wypełniły Ankietę, zostaną wykorzystane w celu przesłania wyników Ankiety tym, którzy wyrazili chęć otrzymania jej wyników. </p>
          <p class="py-0 my-0">3. W związku z przystąpieniem do Ankiety dane osobowe przetwarzane są na podstawie art. 6 ust. 1 lit c ogólnego rozporządzenia o ochronie danych. </p>
          <p class="py-0 my-0">4. Podanie danych osobowych przez Uczestnika jest dobrowolne. </p>
          <p class="py-0 my-0">5. Uczestnikowi przysługuje: </p>
          <p class="py-0 my-0">a) prawo dostępu do podanych danych osobowych,  </p>
          <p class="py-0 my-0">b) prawo żądania sprostowania, usunięcia lub ograniczenia przetwarzania podanych danych osobowych, </p>
          <p class="py-0 my-0">c) prawo do wniesienia sprzeciwu wobec przetwarzania podanych danych osobowych,  </p>
          <p class="py-0 my-0">d) prawo żądania przenoszenia podanych danych osobowych. </p>
          <p class="py-0 my-0">6. Uczestnikowi przysługuje prawo do wniesienia skargi do organu nadzorczego właściwego do ochrony danych osobowych, w sytuacji gdy Uczestnik uzna, że jego dane osobowe są przetwarzane z naruszeniem przepisów. </p>
          <p class="py-0 my-0">7. Dane osobowe Uczestnika mogą być przekazane przez administratora innym podmiotom tj. w szczególności, podmiotom świadczącym usługi serwisowe oraz wsparcia technicznego dla aplikacji Ankiety, systemów informatycznych i/lub serwisu www, w których gromadzone są dane osobowe Uczestników Loterii.</p>
          <p class="py-0 my-0">8. Dane osobowe przechowywane i przetwarzane będą do czasu zgłoszenia przez Państwo żądania: ich usunięcia, ograniczenia przetwarzania, wniesienia sprzeciwu wobec ich przetwarzania lub ich przeniesienia lub tak długo jak Grzegrzółka&Ko Sp. z o.o. będzie wykonywał swoje zadania z zakresu analizy i podsumowania wyników Ankiety. W zakresie roszczeń zgodnie z realizowanymi zadaniami zgodnie z terminami przedawnień dla danego stosunku prawnego. </p>
          <p class="py-0 my-0">9. Dane osobowe Uczestników Loterii nie będą przekazywane do państwa trzeciego, tj. poza Europejski Obszar Gospodarczy. </p>
          <p class="py-0 my-0">10. Administrator nie korzysta z systemów służących do zautomatyzowanego podejmowania decyzji dotyczących przetwarzania danych, w tym profilowania. </p>
        </div>

      <button v-on:click="emailValAndPost" class="next-button mb-4 mx-auto mt-3 rounded font-weight-bold">WYŚLIJ</button>
    </div>

  </div>

</template>

<script>

import axios from "axios";

export default {
  name: 'FormContent',
  props: {
    formnext: Boolean
  },
  data () {
    return {
      agency: false,
      questionCounter: 0,
      slug: '',
      survey: null,
      loading: false,
      errored: false,
      currentOther: [],
      checkboxAnswers: [],
      checkboxMatrix: [],
      postData: {answers: []},
      texts: null,
      endSurvey: false,
      errors: [],
      dependQuestions: [],
      modal: false,
      emailValue: '',
      emailValMsg: '',
      checkBoxOther: [],
      multiToCheck: [],
      errorList: [],
      multiError: [],
      more: false,
      approvalError: [false, false],
      approvals: [
        {req: true, content: 'Wyrażam zgodę na przetwarzanie moich danych osobowych oraz moich odpowiedzi podanych w powyższej ankiecie w celu zbierania danych dotyczących wykorzystania loterii promocyjnych jako narzędzia sprzedaży („Ankieta”) przez Grzegrzółka&Ko Sp. z o.o. z siedzibą w Gdyni (81-335) przy ul. Janka Wiśniewskiego 13, NIP: 7010016236 oraz przez podmioty trzecie. Podmiotom trzecim zostaną udostępnione wyłącznie anonimowe odpowiedzi udzielone w Ankiecie', value: false},
        {req: false, content: 'Wyrażam zgodę na otrzymywanie informacji o wyniku Ankiety drogą elektroniczną na podany powyżej adres poczty elektronicznej wysyłanych przez Grzegrzółka&Ko Sp. z o.o. ', value: false}],
    }
  },
  filters: {
    currencydecimal (value) {
      return value.toFixed(2)
    }
  },
  async mounted () {
    this.loading = true;
    await axios
        .get(`https://api-ankieta.grzegrzolka.com/questionnaire/${this.$route.params.slug}`)
        .then(response => {
          this.survey = response.data.data.question
          this.loading = false
          this.texts = response.data.data
        })
        .catch(error => {
          console.log(error)
          console.log(error.data)
          this.errored = true
        })
        .finally(() => this.loading = false)

    // this.survey.forEach(question=>question.type == 'slider' ? this.postData.answers.push({id: question.id.toString(), answers: ['all ones']}) : '');
    // this.survey.forEach(question=>question.type == 'matrix' ? this.postData.answers.push({id: question.id.toString(), questions: null}) : '');
    this.survey.forEach(question=>question.type == 'once-text' ? this.postData.answers.push({id: question.id.toString(), answers: null, answerId: null}) : '');
    this.survey.forEach(question=>question.type == 'multiple-text' ? this.postData.answers.push({id: question.id.toString(), answers: null, answerId: null}) : '');
    this.survey.forEach(question=>question.type == 'open' ? this.postData.answers.push({id: question.id.toString(), answers: null, answerId: 'open'}) : '');
    this.survey.forEach(question=>question.type == 'multiple-text' ? this.multiToCheck.push(question.id): '');

  },
  methods: {

    sliderAnswers (e, answer ) {
      const currentId = this.postData.answers.map(e => e.answerId).indexOf(answer.id);
      currentId > -1 ? this.postData.answers[currentId] = {id: e.target.name, answers: e.target.value, answerId: answer.id} : this.postData.answers.push({id: e.target.name, answers: e.target.value, answerId: answer.id})
    },
    openAnswer(e) {
      e.target.name == 86 ? this.questionCounter = e.target.value.length : ''
      const myanswer = {id: e.target.name, answers: e.target.value, answerId: 'open'}
      this.postData.answers.find(({id}) => id == e.target.name) ?  this.postData.answers.find(a => a.id === myanswer.id).answers = e.target.value : this.postData.answers.push(myanswer)
    },
    oneAnswer (e, answer) {
      if(answer.content == 'agencja loteryjna') {
        this.agency = true;
      }


      const myanswer = {id: e.target.name, answers: answer.content == 'inne' ? e.target.value : answer.content, answerId: answer.id}
       
      this.postData.answers.find(({id}) => id == e.target.name) ?  this.postData.answers.find(a => a.id === myanswer.id).answers = answer.content : this.postData.answers.push(myanswer)
      this.postData.answers.find(({id}) => id == e.target.name) ?  this.postData.answers.find(a => a.id === myanswer.id).answerId = answer.id : this.postData.answers.push(myanswer)

      setTimeout(()=>{
        if (answer.hiddenQuestions.length > 0) {
          let toDeleteIds = []
          answer.hiddenQuestions.forEach(toHide => toDeleteIds.push(toHide.id))
          toDeleteIds.forEach(toHide => {

            let postToRemove = []
            this.postData.answers.forEach(a=>a.id == toHide ? postToRemove.push(a) : '')
            postToRemove.forEach(a=> {
              this.postData.answers.splice(this.postData.answers.indexOf(a), 1)
            })

            let survToRemove = []
            this.survey.find(item=>item.id == toHide ? survToRemove.push(item) : '')
            survToRemove.forEach(item => this.survey.splice(this.survey.indexOf(item), 1))
          })

        }
      },1)

      setTimeout(()=>{
        if(answer.dependentQuestions.length > 0) {



          answer.dependentQuestions.forEach(depans => this.survey.push(depans))
          answer.dependentQuestions.forEach(depans=> {
            if(depans.type == 'matrix')  {
              let depid = depans.id
              depans.answers.forEach(slider => {
                this.postData.answers.push({id: depid.toString(), answers: null, answerId: (slider.id).toString()})
              })
            }
          });

          answer.dependentQuestions.forEach(depans=> {
            if(depans.type == 'slider')   {
              let depid = depans.id
              depans.answers.forEach(slider => {
                this.postData.answers.push({id: depid, answers: 1, answerId: slider.id})
              })
            }
          });
          // answer.dependentQuestions.forEach(depans=>depans.type == 'matrix' ? this.postData.answers.push({id: depans.id.toString(), questions: null}) : '');
          answer.dependentQuestions.forEach(depans=>depans.type == 'once-text' ? this.postData.answers.push({id: depans.id.toString(), answers: null, answerId: null}) : '');
          answer.dependentQuestions.forEach(depans=>depans.type == 'multiple-text' ? this.postData.answers.push({id: depans.id.toString(), answers: null, answerId: null}) : '');
          answer.dependentQuestions.forEach(depans=>depans.type == 'open' ? this.postData.answers.push({id: depans.id.toString(), answers: null, answerId: 'open'}) : '');
          this.multiError = [];
          this.multiToCheck = [];
          this.survey.forEach(question=>question.type == 'multiple-text' ? this.multiToCheck.push(question.id) : '');



        }
      },2)

      if (answer.endQuestionnaire) {
        this.submit()
      }
      // sortowanie
      setTimeout(()=>{
        this.survey.sort((a, b) => parseInt(a.ord) - parseInt(b.ord))
      },3)
    },
    radioOther (e, answer) {
      const myAnswer = {id: e.target.id, answers: e.target.value, answerId: answer.id}
      this.postData.answers.find(({id}) => id == e.target.id) ?  this.postData.answers.find(a => a.id === myAnswer.id).answers = e.target.value : this.postData.answers.push(myAnswer)
      //sortowanie
      this.survey.sort((a, b) => parseInt(a.ord) - parseInt(b.ord))
    },
    checkboxAnswer (e, answer) {
      const myAnswer = {id: e.target.name, answers: e.target.value, answerId: answer.id}
      if (e.target.checked && e.target.value.length && this.postData.answers.indexOf(this.postData.answers.find(a => a.answerId == e.target.id)) < 0) {
        this.postData.answers.push(myAnswer)
      }
      else if(!e.target.checked && this.postData.answers.indexOf(this.postData.answers.find(a => a.answerId == e.target.id)) > -1) {
        this.postData.answers.splice(this.postData.answers.indexOf(this.postData.answers.find(a => a.answerId == e.target.id)), 1)
      }
    },
    checkboxAnswerText (e, answer) {
      if(this.postData.answers.indexOf(this.postData.answers.find(a => a.answerId == e.target.id)) > -1) {
        this.postData.answers.find(a => a.answerId == e.target.id).answers = e.target.value
      }
      if(this.postData.answers.indexOf(this.postData.answers.find(a => a.answerId == e.target.id)) < 0) {
        const myAnswer = {id: e.target.name, answers: e.target.value, answerId: answer.id}
        this.postData.answers.push(myAnswer)
      }
      if(e.target.value==''){
        this.postData.answers.splice(this.postData.answers.indexOf(this.postData.answers.find(a => a.answerId == e.target.id)), 1)
      }
    },
    matrixAnswers (questionId, e) {
      const currentId = this.postData.answers.map(e => e.answerId).indexOf(e.target.name);
      // console.log({id: questionId, answers: e.target.value, answerId: e.target.name})
      currentId > -1 ? this.postData.answers[currentId] = {id: questionId, answers: e.target.value, answerId: e.target.name} : this.postData.answers.push({id: questionId, answers: e.target.value, answerId: e.target.name})
    },

    submit () {
      const errorsInd = [];
      let errors = [];
      this.postData.answers.forEach(a => a.answers === null || a.answers == "" ? errorsInd.push(a.id) : '');
      // this.postData.answers.forEach(a => a.questions === null ? errorsInd.push(a.id) : '');
      errorsInd.forEach(index => this.survey.forEach(question => question.id == index ? errors.push(question.content) : ''));

      errors.indexOf('Czy jest coś jeszcze czym chciałby/łaby się Pan/i podzielić w realizowaniem loterii, a nie było to poruszone w poprzednich pytaniach?') > -1 ? errors.splice(errors.indexOf('Czy jest coś jeszcze czym chciałby/łaby się Pan/i podzielić w realizowaniem loterii, a nie było to poruszone w poprzednich pytaniach?', 1)) : ''
      errors.indexOf('Jakie inne trudności, wyzwania pojawiły się w dotychczas realizowanych loteriach? (limit 3000 znaków)') > -1 ? errors.splice(errors.indexOf('Jakie inne trudności, wyzwania pojawiły się w dotychczas realizowanych loteriach? (limit 3000 znaków)', 1)) : ''
      errors.indexOf('Wskaż szacunkowy wzrost sprzedaży uzyskany dzięki wykorzystaniu loterii?') > -1 ? errors.splice(errors.indexOf('Wskaż szacunkowy wzrost sprzedaży uzyskany dzięki wykorzystaniu loterii?', 1)) : ''


      //
      var multiInSurvey = [];
      this.multiToCheck.forEach(multi => {
        this.survey.indexOf(this.survey.find(({id})=>id == multi)) > -1 ? multiInSurvey.push(multi) : ''
      })


       if(multiInSurvey.length) {
        const multiAnswerCount = []
        multiInSurvey.forEach(a=>{
          this.postData.answers.forEach(b=> a==b.id ? multiAnswerCount.push(a) : '')
        })

        const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
        multiInSurvey.forEach(multi=> {
          countOccurrences(multiAnswerCount, multi) > 1 ? errors.splice(errors.indexOf(this.survey.find(a=>a.id == multi).content), 1) : ''
        } )

        this.multiToCheck.forEach(tocheck=> {
          if(countOccurrences(multiAnswerCount, tocheck) > 4 && this.multiError.indexOf(this.survey.find(a=>a.id == tocheck).content) < 0) {
            this.multiError.push(this.survey.find(a=>a.id == tocheck).content)
          }
          else if(countOccurrences(multiAnswerCount, tocheck) > 1 && countOccurrences(multiAnswerCount, tocheck) <= 4 ) {
            this.multiError.splice(this.survey.find(a=>a.id == tocheck).content, 1)
          }
         })
      }



      errors = [...new Set(errors)]
      this.errors = errors;
      if(this.errors.length == 0 && this.multiError.length == 0) {
        const multiAnswerCount = []

        this.postData.answers.forEach(a => a.id == this.multiToCheck ? multiAnswerCount.push(a) : '')
        if (multiAnswerCount.length > 1) {

          this.postData.answers.splice(this.postData.answers.indexOf(multiAnswerCount.find(({answerId})=>answerId==null)), 1)
        }
        this.modal = true;
        this.postData.answers.sort((a, b) => parseInt(a.id) - parseInt(b.id))
      }
    },
    emailValAndPost(){
      let postData = {'answers': this.postData.answers, 'user': this.emailValue, 'approvals': this.approvals};

      this.approvals[0].value ? this.approvalError[0] = false : this.approvalError[0] = true;
      // this.approvals[1].value ? this.approvalError[1] = false : this.approvalError[1] = true;


      var emailval = this.emailValue
      var emailValid = false;



      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailval) && emailval.length > 0) { // eslint-disable-line
        emailValid = true; // eslint-disable-line
      }
      if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailval)) { // eslint-disable-line
        this.emailValMsg = 'Wpisz poprawny adres email!' // eslint-disable-line
      } // eslint-disable-line
      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailval)) { // eslint-disable-line
        this.emailValMsg = '' // eslint-disable-line
      } // eslint-disable-line



      if(emailval.length < 1) {
        emailValid = true;
      }


      if (emailValid && this.approvals[0].value && emailval.length > 0) {
        this.loading = true
        axios
            .post(`https://api-ankieta.grzegrzolka.com/questionnaire/save/${this.$route.params.slug}`, postData)
            .then(response => {
              console.log(response)
              this.modal=false
              this.endSurvey= true
              this.loading = false
            })
            .catch(error => {
              console.log(error.response.data.errors)
              this.errorList= error.response.data.errors
              this.errored = true
            })
            .finally(() => this.loading = false)
      }

    }

  }

}

window.onbeforeunload = function(){
  const message = 'Czy na pewno chcesz wyjść ze strony? Twoje dane zostaną utracone.'
  return message;
};

</script>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(0.95);

  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)
  }

  100% {
    transform: scale(0.95);

  }
}
.loading {
  filter: blur(0.1rem);
  transform: scale(1);
  animation: pulse 1s infinite;
  position: relative;
}
.form-range:hover {
  cursor: pointer;
}

input[type="radio"]:hover, input[type="checkbox"]:hover  {
  cursor: pointer;
}
input[type="radio"] {
  transform: scale(1.2);

}

.radio-in-table {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.radio-in-table:hover {
  cursor: pointer;
}

input[type='range']:hover {
  cursor: pointer;
}


label input[type="radio"]:checked ~ span {
  color: black;
}
label input[type="checkbox"]:checked ~ span {
  color: black;
}
label.btn span {
  font-size: 16px;
}

label input[type="radio"] {
  color: #c8c8c8;
  display: inline;
}

div[data-toggle="buttons"] label {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 2em;
  text-align: left;
  white-space: nowrap;
  vertical-align: top;
  cursor: pointer;
  background-color: none;
  border: 0px solid #c8c8c8;
  border-radius: 3px;
  color: #c8c8c8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
div[data-toggle="buttons"] label:hover {
  /* color: black; */
}
div[data-toggle="buttons"] label:active,
div[data-toggle="buttons"] label.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
label input[type="radio"]:checked ~ span {
  color: black;
}
label input[type="checkbox"]:checked ~ span {
  color: black;
}

input[type="radio"] {
  transform: scale(1.3);
}

button:hover,
button:focus {
  background: #0053ba;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}


.form-container {
  margin: 0 auto;
  background: white;
  box-shadow: 6px 6px 14px 0 rgba(0, 0, 0, 0.2), -8px -8px 18px 0 rgba(255, 255, 255, 0.55);
  padding: 60px 60px
}
th, td {
  border: 1px solid gray;
}

label:hover {
  cursor: pointer;
}
.next-button {
  display: inline-block;
  border: none;
  padding: 1.4rem 2.4rem;
  margin: 0;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: white !important;
}
/*.email-modal-container {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  position: static;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  background-color: rgba(255, 255, 255, .15);*/
/*  backdrop-filter: blur(2px);*/


/*}*/
.email-modal {
  background-color: white;
  border: 3px #0069ed  solid;
  border-radius: 7px;
  padding: 40px;
  position: relative;
}
.to-blur{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.btn-closes {
  width: 21px;
  height: 21px;
  background-color: #0069ed;
  color: white !important;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  position: absolute;
  right: 8px;
  top: 8px;
}
.moreRules {
  font-size: 11px !important;
  max-height: 140px;
  overflow: scroll;
}
.approve {
  font-size: 13px;
}


</style>