import Vue from 'vue'
import VueRouter from 'vue-router'
// import Grzeform from "@/components/Grzeform";
import FormContent from "@/components/FormContent";
import FirstPage from "@/components/FirstPage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Grzegrzolka',
    component: FirstPage
  },
  {
    path: '/:slug',
    name: 'Ankieta',
    component: FormContent
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes, mode: 'history'
})

export default router
