<template>
  <div class="row col-12 mx-auto p-0">
<!--    <first-page :slug="slug" v-if="!formnext" :formnext="formnext"/>-->
<!--    <form-content :survey="survey" :loading="loading" :errored="errored"/>-->
    <router-view></router-view>
  </div>
</template>

<script>
// import FirstPage from "@/components/FirstPage";
// import FormContent from "@/components/FormContent";
// import axios from "axios";

export default {
  name: 'Grzeform',
  // components: {FirstPage, FormContent},
  data () {
    return {
      formnext: false,
      loading: true,
      errored: false,
      survey: null,
      slug: ''
    }
  },
  methods: {
    updateFormNext: function (e) {
      this.formnext = e
      // console.log(this.survey)
      }
    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  padding-top: 50px;

  background: whitesmoke;
  font-family: "Exo", sans-serif;
}
* {
  color: gray !important;
}
@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */    font-size: 12px;}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */   font-size: 13px;}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */   font-size: 14px; }
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */   font-size: 15px;}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */   font-size: 16px; }
@media (min-width:1281px) { /* hi-res laptops and desktops */   font-size: 40px;}

</style>
